/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/Seo'
import TextImage from '../components/sections/TextImage'
import HeroAndImage from '../components/sections/HeroAndImage'
// import Partners from '../components/sections/Partners'
import Testimonial from '../components/sections/Testimonial'
import Cta from '../components/sections/Cta'

const IndexPage = ({ data: { allContentfulHomePage } }) => {
  const { metadata } = allContentfulHomePage.nodes[0]
  const { sections } = allContentfulHomePage.nodes[0]
  return (
    <>
      <SEO
        title={metadata.title}
        description={metadata.description}
        meta={[
          {
            property: 'og:image:width',
            content: '561',
          },
          {
            property: 'og:image:height',
            content: '268',
          },
          {
            property: 'og:image:alt',
            content: 'description_of_site',
          },
          {
            property: 'og:image',
            content: 'https://innential.com/metaimg.png',
          },
          {
            property: 'og:url',
            content: 'https://innential.com',
          },
        ]}
      />
      {sections.map((section) => {
        const { id, typeName } = section

        switch (typeName) {
          case 'Hero':
            return <HeroAndImage key={id} {...section} />

          /* case '_Partners':
            return <Partners key={id} {...section} /> */

          case '_TextAndImage':
            return <TextImage key={id} {...section} />

          case '_Testimonial':
            return <Testimonial key={id} {...section} />

          case '_CTA':
            return <Cta key={id} {...section} />

          default:
            return null
        }
      })}
    </>
  )
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    allContentfulHomePage(filter: { name: { eq: "HomePage" } }) {
      nodes {
        name
        metadata {
          title
          description
        }
        sections {
          ... on ContentfulSectionSuperhero {
            id
            typeName
            heroData: data {
              headline
              subline {
                raw
              }
              callToAction {
                link
                title
              }
              heroImage {
                file {
                  url
                }
                gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
              }
              partnersHome {
                id
                headline
                typeName
                partnersData: data {
                  details {
                    name
                    link
                    image {
                      file {
                        url
                      }
                      gatsbyImageData(
                        width: 120
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
              extraText {
                text
                icon {
                  file {
                    url
                  }
                  gatsbyImageData(width: 20, quality: 10, layout: CONSTRAINED)
                }
              }
            }
          }
          ... on ContentfulSectionCta {
            id
            typeName
            ctaData: data {
              text {
                raw
              }
              button {
                link
                title
              }
              image {
                file {
                  url
                }
                gatsbyImageData(width: 180, quality: 90, layout: CONSTRAINED)
              }
            }
          }
          ... on ContentfulSectionPartners {
            id
            headline
            typeName
            partnersData: data {
              details {
                name
                link
                image {
                  file {
                    url
                  }
                  gatsbyImageData(width: 320, quality: 90, layout: CONSTRAINED)
                }
              }
            }
          }
          ... on ContentfulSectionTestimonial {
            id
            typeName
            testimonialData: data {
              clientDetails {
                id
                name
                position
                clientImage {
                  file {
                    url
                  }
                  gatsbyImageData(width: 320, quality: 90, layout: CONSTRAINED)
                }
                companyLogo {
                  file {
                    url
                  }
                  gatsbyImageData(width: 320, quality: 90, layout: CONSTRAINED)
                }
              }
              callToAction {
                link
                title
              }
              quote {
                internal {
                  content
                }
              }
              quoteTitle
            }
          }
          ... on ContentfulSectionTextAndImage {
            id
            headline
            typeName
            taIData: data {
              ... on ContentfulTextAndImage {
                imagePosition
                id
                text {
                  headline
                  paragraph
                }
                image {
                  file {
                    url
                  }
                  gatsbyImageData(quality: 100, layout: CONSTRAINED)
                }
              }
              ... on ContentfulImageAndCta {
                image {
                  file {
                    url
                  }
                  gatsbyImageData(quality: 100, layout: CONSTRAINED)
                }
                cta {
                  title
                  link
                }
              }
            }
          }
        }
      }
    }
  }
`
