/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import device from '../../utils/mq'

const PartnersTitle = styled.h2`
  color: #191919;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 18px;
`

const PartnersLogos = styled.div`
  max-width: 930px;
  position: relative;
  margin: 0px auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  -webkit-box-align: center;
  align-items: center;
  gap: 20px;
  padding-inline: 12px;
`

const PartnersContainer = styled.div`
  max-width: 930px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 100px;
  width: 100%;
`

const PartnerItem = styled.div`
  display: flex;
  max-width: 180px;
  justify-content: space-evenly;
  align-items: center;
`

// eslint-disable-next-line prettier/prettier
const PartnersHome = ({ headline, partnersData }) => (
  <PartnersContainer>
    <PartnersTitle>{headline}</PartnersTitle>
    {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    <PartnersLogos>
      {partnersData.map((item) => (
        <PartnerItem key={item?.details?.name}>
          {/* <GatsbyImage
              image={item?.details?.image?.gatsbyImageData}
              alt={item?.details?.name}
            /> */}

          <img
            src={item?.details?.image?.file?.url}
            alt={item?.details?.name}
          />
        </PartnerItem>
      ))}
    </PartnersLogos>
  </PartnersContainer>
)

export default PartnersHome

export const fragment = graphql`
  fragment PartnersSection on partnersData {
    details {
      name
      image {
        file {
          url
        }
      }
    }
  }
`
