/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import device from '../../utils/mq'
import BG from '../../assets/top-bg.png'
import PartnersHome from './PartnersHome'

const StyledSection = styled.section`
  overflow-x: hidden;
  width: 100%;
  margin-top: -92px;
  ${'' /* background-image: url(${BG}); */}
  background: linear-gradient(180deg, #FFF 0%, #E3E6FA 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media ${device.laptop} {
    ${'' /* margin-top: -180px; */}
  }
  @media (min-width: 1580px) {
    ${'' /* margin-top: -160px; */}
  }
`

const Hero = styled.div`
  max-width: 1300px;
  padding: 70px 50px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  a {
    color: #fff;
    background-color: #5a55ab;
    padding: 10px 24px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.25px;
    border: 2px solid #5a55ab;
    border-radius: 26px;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    width: auto;
    height: auto;
    box-shadow: 0 25px 30px -10px rgba(90, 85, 171, 0.6);
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

    &:hover {
      color: #5a55ab;
      background-color: #fff;
      border-color: #fff;
    }
  }
`
const HeroTextAndIcon = styled.div`
  display: flex;
  align-items: center;
  color: #7839cd;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.78px;
  text-transform: uppercase;
  margin-bottom: 21px;
  gap: 6px;
  justify-content: center;
  span {
    padding-left: 3px;
  }
  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`
const HeroTitle = styled.h1`
  margin: 0 0 30px;
  font-size: 36px;
  color: #1b1b1b;
  ${'' /* max-width: 600px; */}
  font-stretch: normal;
  letter-spacing: normal;
  font-weight: 700;
  line-height: 46px;

  @media (min-width: 1210px) {
    font-size: 40px;
    line-height: 48px;
    max-width: 400px;
  }
`

const HeroSubline = styled.div`
  color: #757575;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 50px;
  font-weight: 500;
  margin-top: 0px;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 28px;
  }
  @media ${device.laptop} {
    max-width: 430px;
  }
`
const HeroText = styled.div`
  align-self: center;
  margin-bottom: 80px;
  @media (min-width: 1024px) {
    padding-top: 120px;
    min-width: 554px;
    margin-right: 20px;
  }
  @media (min-width: 1225px) {
    padding-top: 70px;
  }
  @media (min-width: 1324px) {
    padding-top: 70px;
  }
  @media (min-width: 1524px) {
    padding-top: 0px;
    margin-bottom: 90px;
  }
`

const HeroImage = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    margin-left: -40px;
    align-self: center;
  }
  @media (min-width: 1024px) {
    div {
      min-width: 650px;
    }
  }
  @media (min-width: 1225px) {
    div {
      min-width: 750px;
    }
  }
  @media (min-width: 1324px) {
    div {
      min-width: 775px;
    }
  }
  @media (min-width: 1524px) {
    div {
      min-width: 950px;
    }
  }
`

const HeroAndImage = ({ heroData }) => {
  const subline = renderRichText(heroData?.subline)

  return (
    <StyledSection>
      <Hero>
        {/* <pre>{JSON.stringify(heroData, null, 2)}</pre> */}
        <HeroText>
          <HeroTextAndIcon>
            <span>{heroData?.extraText?.text}</span>
            <img
              src={heroData?.extraText?.icon?.file?.url}
              alt={heroData?.extraText?.text}
            />
          </HeroTextAndIcon>
          {/* <HeroTextAndIcon>
            <span>{heroData?.extraText?.text}</span>
            <GatsbyImage
              image={heroData?.extraText?.icon?.gatsbyImageData}
              alt={heroData?.extraText?.text}
            />
          </HeroTextAndIcon> */}
          <HeroTitle>{heroData?.headline}</HeroTitle>
          <HeroSubline>{subline}</HeroSubline>
          {/* <HeroTitle>
            Supercharge your team's growth with Learning Assistant
          </HeroTitle>
          <HeroSubline>
            Run all your talent initiatives from a single platform, supported by
            AI and a library of 70,000+ resources from 50+ learning providers.
          </HeroSubline> */}
          <a href={heroData.callToAction.link}>{heroData.callToAction.title}</a>
        </HeroText>
        <HeroImage>
          <GatsbyImage
            image={heroData?.heroImage?.gatsbyImageData}
            alt="Hero image"
            style={{ maxWidth: `600px` }}
          />
        </HeroImage>
      </Hero>
      <PartnersHome
        headline={heroData?.partnersHome?.headline}
        partnersData={heroData?.partnersHome?.partnersData}
      />
      {/* <PartnersContainer>
        <PartnersTitle>TRUSTED BY TOP COMPANIES</PartnersTitle>
        <PartnersLogos>
          {heroData?.partners?.map((partner) => (
            <GatsbyImage
              key={partner?.details?.name}
              image={partner?.details?.image?.gatsbyImageData}
              alt={partner?.details?.name}
            />
          ))}
        </PartnersLogos>
      </PartnersContainer> */}
    </StyledSection>
  )
}
export default HeroAndImage
